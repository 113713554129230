<template>
  <div class="aboutUs flex-row">
    <div v-if="debrisLanguageType == 1" class="container-left flex-col">
      <span>关于我们</span>
      <span
        >2022年，Debris
        Studio由导演兼创意总监陈西峰，艺术总监张敏方，制片人Jason
        Gu联手草创，三位资深动画人曾制作并出品过多部国内外获奖作品。</span
      >
    </div>
    <div
      v-if="debrisLanguageType == 2"
      class="container-left en-language flex-col"
    >
      <span>About Us</span>
      <span
        >2022, Debris Studio Director and Creative Director Chen Xifeng, Art
        Director Zhang Minfang, and Producer Jason Gu. The three senior
        animators have created and produced many award-winning works at home and
        abroad.</span
      >
    </div>
    <div class="container-right flex-row justify-center align-center">
      <div class="item1 flex-col">
        <img src="../assets/image/others/1.jpg" alt="" />
        <span v-if="debrisLanguageType == 1" class="span1">西风</span>
        <span v-if="debrisLanguageType == 1" class="span2">导演</span>
        <span v-if="debrisLanguageType == 2" class="span1 poppins"
          >Xi Feng</span
        >
        <span v-if="debrisLanguageType == 2" class="span2 alibaba-PuHuiTi-Light"
          >Director</span
        >
      </div>
      <div class="item2 flex-col">
        <img src="../assets/image/others/2.jpg" alt="" />
        <span v-if="debrisLanguageType == 1" class="span1">张敏方</span>
        <span v-if="debrisLanguageType == 1" class="span2">美术指导</span>
        <span v-if="debrisLanguageType == 2" class="span1 poppins"
          >Zhang Minfang</span
        >
        <span v-if="debrisLanguageType == 2" class="span2 alibaba-PuHuiTi-Light"
          >Art Director</span
        >
      </div>
      <div class="item3 flex-col">
        <img src="../assets/image/others/3.jpg" alt="" />
        <span v-if="debrisLanguageType == 1" class="span1">Jason</span>
        <span v-if="debrisLanguageType == 1" class="span2">制片人</span>
        <span v-if="debrisLanguageType == 2" class="span1 poppins">Jason</span>
        <span v-if="debrisLanguageType == 2" class="span2 alibaba-PuHuiTi-Light"
          >Producer</span
        >
      </div>
    </div>
    <img
      class="close"
      @click="goBack"
      src="../assets/icon/close_b.png"
      alt=""
    />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    debrisLanguageType() {
      return this.$store.state.debrisLanguageType;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.aboutUs {
  width: 100%;
  height: 8.16rem;
  position: relative;

  .container-left {
    width: 3.84rem;
    height: 100%;
    padding: 0.26rem;
    background-color: #c5c5c5;

    span {
      display: inline-block;
      width: 3.35rem;
      line-height: 0.24rem;
      &:nth-child(1) {
        margin-bottom: 1.45rem;
        font-size: 0.4rem;
        line-height: 0.48rem;
        font-family: "Alibaba-PuHuiTi-Bold";
      }
    }
  }
  .en-language {
    span {
      font-family: "Poppins";
      &:nth-child(1) {
        font-family: "Poppins Bold";
      }
    }
  }

  .container-right {
    width: calc(100% - 3.84rem);
    height: 100%;
    background-color: #ebebeb;

    .item1,
    .item2,
    .item3 {
      width: 3.36rem;
      height: 3.36rem;

      img {
        width: 100%;
        height: 100%;
      }

      .span1 {
        font-family: "Alibaba-PuHuiTi-Bold";
        margin-top: 0.24rem;
        line-height: 0.24rem;
      }

      .span2 {
        line-height: 0.24rem;
        margin-top: 0.02rem;
      }
      .poppins {
        font-family: "Poppins";
      }
      .alibaba-PuHuiTi-Light {
        font-family: "Alibaba-PuHuiTi-Light";
      }
    }

    .item2 {
      margin: 0 0.47rem;
    }
  }

  .close {
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    left: 0.23rem;
    bottom: 0.23rem;
    cursor: pointer;
  }
}
</style>
